<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-app id="inspire">
      <v-layout wrap justify-center class="signup_page">
        <v-flex xs12 sm6 md6 lg4 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12 lg11 xl8>
              <v-card flat rounded="lg" color="#FFFFFF">
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm10 py-4 text-center>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #000000;
                            font-size: 20px;
                          "
                        >
                          Create an account
                        </span>
                        <br />
                        <span
                          style="
                            font-family: poppinsregular;
                            letter-spacing: 0.5px;
                            font-size: 15px;
                            color: #000000;
                          "
                        >
                          Please sign up to create your new account.
                        </span>
                      </v-flex>
                      <v-flex xs12 pt-2 text-left>
                        <span class="textField">Full Name </span>
                        <v-text-field
                          v-model="user.name"
                          outlined
                          hide-details
                          placeholder="Enter Name"
                          dense
                          color="#979797DE"
                        />
                      </v-flex>
                      <v-flex xs12 pt-3 text-left>
                        <span class="textField"> Phone number </span>
                        <v-text-field
                          v-model="user.phone"
                          type="number"
                          outlined
                          hide-details
                          placeholder="Enter Phone"
                          dense
                          color="#979797DE"
                        />
                      </v-flex>
                      <v-flex xs12 pt-3 text-left>
                        <span class="textField">Email </span>
                        <v-text-field
                          v-model="user.email"
                          outlined
                          hide-details
                          placeholder="Enter Email"
                          dense
                          color="#979797DE"
                        />
                      </v-flex>
                      <v-flex xs12 pt-3 text-left>
                        <span class="textField"> Password </span>
                        <v-text-field
                          v-model="user.password"
                          outlined
                          dense
                          hide-details
                          placeholder="Enter Password"
                          color="#979797DE"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                        />
                      </v-flex>
                      <v-flex xs12 pt-3 text-left>
                        <span class="textField"> Confirm Password </span>
                        <v-text-field
                          v-model="user.confirmPassword"
                          outlined
                          dense
                          placeholder="Confirm Password"
                          color="#979797DE"
                          :append-icon="
                            showPassword2 ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="showPassword2 ? 'text' : 'password'"
                          @click:append="showPassword2 = !showPassword2"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs1>
                            <v-checkbox v-model="checkbox"></v-checkbox>
                          </v-flex>
                          <v-flex xs11>
                            <span
                              style="
                                color: #000000;
                                font-family: opensansregular;
                                font-size: 14px;
                              "
                            >
                              By signing up you agree to our <a href="/TermsAndConditions">Terms and
                              Conditions</a>  of service's and <a href="/PrivacyPolicy">Privacy Policy</a></span
                            ></v-flex
                          >
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 py-6>
                        <v-btn
                          block
                          color="#30B868"
                          dark
                          :ripple="false"
                          depressed
                          @click="verifyInput"
                        >
                          <span style="font-family: opensanssemibold">
                            Sign up
                          </span>
                        </v-btn>
                      </v-flex>
                      <v-flex xs12 pb-5>
                        <router-link to="/loginForm">
                          <span
                            style="
                              color: #000000;
                              font-family: opensansregular;
                              font-size: 15px;
                            "
                            >← Back to </span
                          ><span
                            style="
                              color: #30b868;
                              font-family: poppinsmedium;
                              font-size: 15px;
                            "
                            >&nbsp;LOG IN</span
                          >
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      user: {
        name: null,
        phone: null,
        email: null,
        password: null,
        confirmPassword: null,
      },
      showPassword: false,
      showPassword2: false,
      checkbox: true,
    };
  },
  methods: {
    verifyInput() {
      var that = this;
      var flag = false;
      var phoneformat = /^\d{10}$/;
      var nameformat = /^[a-zA-Z0-9 .]{3,50}$/;
      var mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      Object.keys(this.user).forEach(function (key) {
        if (!that.user[key]) {
          flag = true;
        }
      });
      if (flag) {
        this.msg = "Please Provide all Details";
        this.showSnackBar = true;
        return;
      } else if (!this.user.phone.match(phoneformat)) {
        this.msg = "Please Provide valid Phone Number";
        this.showSnackBar = true;
        return;
      } else if (this.user.password != this.user.confirmPassword) {
        this.msg = "Password doesn't match";
        this.showSnackBar = true;
        return;
      } else if (this.user.name.length < 3 || this.user.name.length > 50) {
        this.msg = "Name should be between 3 and 50 characters length";
        this.showSnackBar = true;
        return;
      } else if (!this.user.name.match(nameformat)) {
        this.msg = "Name can only contain alphabets, number, [space], [dot]";
        this.showSnackBar = true;
        return;
      } else if (!this.user.email.match(mailformat)) {
        this.msg = "Please Provide valid Email Id";
        this.showSnackBar = true;
        return;
      }
      else if (this.checkbox==false) {
        this.msg = "Please agree policy";
        this.showSnackBar = true;
        return;
      } else {
        this.signUp();
      }
    },
    signUp() {
      this.user.guestId = localStorage.getItem("userId");
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/customer/signUp",
        data: this.user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$store.commit("userType", response.data.role);
            this.$store.commit("userData", response.data.userData);
            this.$store.commit("loginUser", response.data);
            localStorage.setItem("token", response.data.token);
            this.$store.commit("changeCart", response.data.totalLength);
            localStorage.removeItem("userId");
            // this.$router.push("/");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
<style>
  .signup_page {
      background-image: url("./../../assets/images/bgImage.png");
      background-attachment: fixed;
      height: 800px;
      background-size: cover;
      background-color: #1c273c;
  }</style>